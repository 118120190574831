import React from 'react';

const services = [
  { name: 'Business Consulting', description: 'Strategic advice for business growth.' },
  { name: 'Software Development', description: 'Custom software solutions for your needs.' },
  { name: 'Data Analytics', description: 'Data-driven insights for informed decisions.' },
  { name: 'Digital Marketing', description: 'Strategies to boost your online presence.' },
];

const Services: React.FC = () => {
  return (
    <div className="py-8">
      <h1 className="text-3xl font-bold text-primary mb-8">Our Services</h1>
      <div className="grid md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div key={index} className="bg-white p-6 border border-gray-200">
            <h2 className="text-xl font-semibold text-primary mb-2">{service.name}</h2>
            <p className="text-secondary">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
