import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Professional Services</h3>
            <p className="text-gray-300">Providing expert solutions since 2023</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {['Services', 'About Us', 'Contact'].map((item) => (
                <li key={item}>
                  <a href={`/${item.toLowerCase().replace(' ', '-')}`} className="text-gray-300 hover:text-white transition duration-300">{item}</a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <p className="text-gray-300">123 Business St, Suite 100<br />City, State 12345<br />Phone: (123) 456-7890</p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-sm text-center text-gray-300">
          <p>&copy; {new Date().getFullYear()} Professional Services. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
