import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <div className="space-y-16 py-16">
      <section className="text-center">
        <h1 className="text-4xl font-bold text-primary mb-4">Welcome to My Professional Services</h1>
        <p className="text-xl text-secondary mb-8 max-w-2xl mx-auto">This is a test deployment. If you can see this message, the deployment was successful!</p>
        <Link to="/services" className="bg-accent hover:bg-stone-600 text-white font-semibold py-3 px-8 rounded-md shadow-soft transition duration-300">
          Explore Our Services
        </Link>
      </section>

      <section className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {['Consulting', 'Development', 'Analytics'].map((service, index) => (
            <div key={index} className="bg-white p-8 rounded-md shadow-soft hover:shadow-md transition duration-300">
              <h2 className="text-xl font-semibold text-primary mb-4">{service}</h2>
              <p className="text-secondary">Tailored solutions to meet your specific business requirements.</p>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-primary mb-8 text-center">Why Choose Us?</h2>
          <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            {[
              'Expert team with proven track record',
              'Customized solutions for your unique needs',
              'Commitment to client success',
              'Innovative approaches to complex problems'
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-4">
                <div className="flex-shrink-0 w-10 h-10 bg-accent rounded-full flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                </div>
                <p className="text-secondary">{item}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
