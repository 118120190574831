import React from 'react';

const About: React.FC = () => {
  return (
    <div className="py-8">
      <h1 className="text-3xl font-bold text-primary mb-8">About Professional Services</h1>
      
      <div className="space-y-8">
        <section className="bg-white p-6 border border-gray-200">
          <h2 className="text-xl font-semibold text-primary mb-4">Our Company</h2>
          <p className="text-secondary">
            Professional Services is a leading firm dedicated to helping businesses thrive in today's competitive landscape. With our team of expert consultants and cutting-edge technologies, we deliver tailored solutions that drive growth and success.
          </p>
        </section>

        <section className="bg-white p-6 border border-gray-200">
          <h2 className="text-xl font-semibold text-primary mb-4">Our Mission</h2>
          <p className="text-secondary">
            To empower businesses with innovative solutions and unparalleled expertise, enabling them to achieve their full potential and succeed in an ever-changing market.
          </p>
        </section>

        <section className="bg-white p-6 border border-gray-200">
          <h2 className="text-xl font-semibold text-primary mb-4">Our Team</h2>
          <p className="text-secondary">
            Our diverse team of professionals brings a wealth of experience from various industries. We are passionate about what we do and committed to delivering excellence in every project we undertake.
          </p>
        </section>
      </div>
    </div>
  );
};

export default About;
